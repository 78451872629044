/**
 * Created by stapletont on 05/01/2015.
 */

define('app',
  [
    'controlTower',
    'element',
    'ajax',
    'utils',
    'siteObj'
  ],

  function(
    controlTower,
    Element,
    ajax,
    utils,
    siteObj) {

    let element = new Element();

    if (siteObj.requireCustomPolyfill) {
        require(['polyfill']);
    }

    if (siteObj.config && siteObj.config.hasServiceWorker === 'true') {
        require(['serviceWorkerController']);
    }

    return {
      select: (...args) => {
        return element.init.apply(element, args);
      },

      subscribe: controlTower.subscribe,

      publish: controlTower.publish,

      clear: controlTower.clear,

      controlTower: controlTower,

      ajax: ajax,

      element: element,

      on: element.on.bind(element),

      utils: utils,

      /**
       * Return list of methods available within an object
       * @returns {object}
       */
      listFunctions: function() {
        return this;
      }
    };
  });
